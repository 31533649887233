import React, { Fragment } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Helmet } from 'react-helmet'
import { css } from '@styled-system/css'

import {
  Box,
  FormGroup,
  Paragraph,
  P,
  Span,
  H1,
  Button,
  LoadingSpinner,
} from '@allied-solutions/affinity'

import { useFetchBorrower, useStore } from '../../hooks'
import { AppLayout, Layout, NextButton } from '../../components'
import illustration from './Illustration.svg'
import { LinkAnchor } from '../../components/LinkAnchor'

const SubmitInsurancePage = props => {
  const { state } = useStore()
  const { handleSubmit } = useFetchBorrower(
    '/enter-reference-id/confirm-identity/'
  )

  return (
    <Layout>
      <Helmet>
        <title>Verify My Insurance</title>
        <meta
          name="description"
          content="Start the 5-minute insurance verification process on your own by entering your reference ID number from the letter or email you received."
        />
      </Helmet>
      <Formik
        initialValues={{
          refId: '',
          ...state.form,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          refId: Yup.string().required('Please enter a valid Reference ID.'),
        })}
      >
        {props => (
          <main>
            <Form noValidate>
              <AppLayout
                leftSide={
                  // <Box mt={[null, '3rem', null, null]}>
                  <>
                    <Paragraph
                      mb={6}
                      typeStyle="labelLarge"
                      textAlign="left"
                      color="gray600"
                    >
                      Getting Started
                    </Paragraph>
                    <H1 className="h5" typeStyle="h5">
                      Enter your reference ID.
                    </H1>
                    <Paragraph
                      typeStyle="bodyMedium"
                      textAlign="left"
                      color="secondary"
                      mb={8}
                    >
                      Your reference ID can be found in the email or letter your
                      financial institution sent you.
                      <br />
                      <br />
                      <Span fontWeight={600}>Note:</Span> It will be helpful to
                      have your insurance policy before you start.
                    </Paragraph>

                    <FormGroup id="refId" required mt={18} focusStyleTest>
                      <FormGroup.Label
                        hint={
                          <LinkAnchor
                            id="EnterRefId__Button--noRefId"
                            to="/no-reference-id/"
                            tabIndex={1}
                          >
                            <P
                              mb={0}
                              typeStyle="caption"
                              textAlign="right"
                              color="info"
                            >
                              Don't have an ID?
                            </P>
                          </LinkAnchor>
                        }
                        visible
                      >
                        Reference ID
                      </FormGroup.Label>
                      <FormGroup.InputGroup tabIndex={-1}>
                        <FormGroup.InputGroup.Input
                          tabIndex={0}
                          pattern="[0-9]*"
                        />
                      </FormGroup.InputGroup>
                    </FormGroup>

                    {/* </Box> */}
                  </>
                }
                rightSide={
                  <img
                    src={illustration}
                    alt=""
                    css={css({
                      display: ['none', null, null, 'block'],
                      mx: 'auto',
                    })}
                  />
                }
                footerButtons={
                  <Box display="flex">
                    <NextButton
                      tabIndex="0"
                      ml="auto"
                      formikProps={props}
                      id="EnterRefId__Button--next"
                    >
                      Get Started
                    </NextButton>
                  </Box>
                }
              />
            </Form>
          </main>
        )}
      </Formik>
    </Layout>
  )
}

export default SubmitInsurancePage
